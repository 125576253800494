<template>
	<div class="card p-grid">
		<div class="p-col-12">	
			<h4>{{$store.state.titulo}}</h4> 
			<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				<template #header>
					<div class="table-header">
						<div>Registros: {{ products.length }}</div>
                        <Dropdown v-model="almacen" :options="almacenes" optionLabel="nombre" placeholder="Seleccionar almacén ..." @change="Buscar"/>       
						<div>
							<Button @click="Buscar" icon="pi pi-search" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="'Buscar'" :disabled="almacen==null"/>
						</div>
					</div>
				</template>
				<Column field="nombre" header="Producto" :sortable="true">
					<template #body="slotProps">
						{{slotProps.data.nombre}}
					</template>
				</Column>
				<Column field="codigo" header="Código" headerStyle="width: 25%">
					<template #body="slotProps">
						<BarcodeGenerator :value="slotProps.data.codigo" :format="slotProps.data.format" :lineColor="'#000'" :width="1.5" :height="20" :elementTag="'img'"/>
					</template>
				</Column>

                <Column field="disponibilidad" header="Cantidad" headerStyle="width: 10%">
					<template #body="slotProps">
						{{slotProps.data.disponibilidad}}
					</template>
				</Column>
				<Column header="Opciones" headerStyle="width: 10%">
					<template #body="slotProps">
						<Button @click="Ver(slotProps.data)" icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2 p-mb-2" v-tooltip.bottom="'Ver'" v-if="edit"/>
					</template>
				</Column>
			</DataTable>
		</div>

		<Dialog v-model:visible="dialog" :style="{width: size}" header="Detalle" :modal="true" class="p-fluid" :maximizable="true">
            <br>
			<DataTable :value="detail" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				<Column field="date" header="Fecha">
					<template #body="slotProps">
						{{slotProps.data.date}}
					</template>
				</Column>
				<Column field="nombre" header="Producto">
					<template #body="slotProps">
						{{slotProps.data.nombre}}
					</template>
				</Column>
				
				<Column field="id" header="Lote">
					<template #body="slotProps">
						{{slotProps.data.id}}
					</template>
				</Column>
				<Column field="vencimiento" header="Vencimiento">
					<template #body="slotProps">
						<strong v-if="slotProps.data.dias>0" style="color: green;">
							{{slotProps.data.vence}} - {{ slotProps.data.dias+' días' }}
						</strong>
						<strong v-else style="color: red;">
							{{slotProps.data.vence}}
						</strong>					
					</template>
				</Column>
				<Column field="cantidad" header="Cantidad">
					<template #body="slotProps">
						{{slotProps.data.cantidad}}
					</template>
				</Column>
				<Column field="disp" header="Disp.">
					<template #body="slotProps">
						{{slotProps.data.disp}}
					</template>
				</Column>
			</DataTable>
           
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="dialog=false"/>
			</template>
		</Dialog>
		

	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Inventario','Inventario');
	import BarcodeGenerator from "../components/BarcodeGenerator.vue";

	export default {
		components: {
			BarcodeGenerator
		},
		data() {
			return {
				actualizar: 0,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				filters: {},
				selectedCustomers: null,
				dialog: false,
				size: '40vw',
				size2: '40vw',
				productos: [],
                almacenes: [],
                opcion: {id: 1, nombre: 'Código'},
                opciones: [
                    {id: 1, nombre: 'Código'},
                    {id: 2, nombre: 'Nombre'},
                ],
                codigo: null,
                nombre: null,
                almacen: null,
				detail: {},
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){
				this.size = '90vw';
				this.size2 = '90vw';
			}
            this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.almacenes = response.result.almacenes;
					this.Actualizar();
				}); 
			},
			Buscar() {
				this.products = [];
				Consulta.Procesar('Buscar',{
					product: this.almacen,
				}).then(response => {
					//this.$store.state.error =response;
					this.products = response.result;
				}); 
			},
			Ver(data) {
				this.detail = {};
				var dat = data;
				dat.almacen = this.almacen;
				Consulta.Procesar('Ver',{
					product: dat,
				}).then(response => {
					//this.$store.state.error =response;
					this.detail = response.result;
					this.dialog = true;
				}); 
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
