<template>
	<div class="card p-grid">
		<div class="p-col-12">	
			<h4>{{$store.state.titulo}}</h4> 
			<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				<template #header>
					<div class="table-header">
						<div>Registros: {{ products.length }}</div>
                        <Dropdown v-model="almacen" :options="almacenes" optionLabel="nombre" placeholder="Seleccionar almacén ..."/> 
                        <div>
                            <SelectButton v-model="opcion" :options="opciones" optionLabel="nombre"/>
                        </div>   
                        <div>
                            <InputText id="name" v-model="fecha_ini" type="date" required="true"/>
                            <InputText id="name" v-model="fecha_fin" type="date" required="true"/>
                        </div>      
						<div>
							<Button @click="Buscar" icon="pi pi-search" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="'Buscar'" 
                                :disabled="almacen==null || almacen==null || fecha_ini==null || fecha_fin==null || opcion==null"/>
							<Button @click="VerPDF" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2 p-mb-2" v-tooltip.bottom="'PDF'" :disabled="products.length==0"/>
							<Button @click="VerXLS" icon="pi pi-file-excel" class="p-button-rounded p-button-primary p-mr-2 p-mb-2" v-tooltip.bottom="'XLS'" :disabled="products.length==0"/>
						</div>
					</div>
				</template>
                <Column field="date" header="Fecha" headerStyle="width: 10%">
					<template #body="slotProps">
						{{slotProps.data.date}}<br>{{ slotProps.data.inv }}
					</template>
				</Column>
				<Column field="codigo" header="Código" headerStyle="width: 20%">
					<template #body="slotProps">
						<BarcodeGenerator :value="slotProps.data.codigo" :format="slotProps.data.format" :lineColor="'#000'" :width="1.5" :height="20" :elementTag="'img'"/>
					</template>
				</Column>
				<Column field="nombre" header="Producto" :sortable="true">
					<template #body="slotProps">
						{{slotProps.data.nombre}}
					</template>
				</Column>
                <Column field="tipo_n" header="Tipo" headerStyle="width: 20%">
					<template #body="slotProps">
						{{slotProps.data.tipo_n}}
					</template>
				</Column>
				<Column field="unidad_n" header="Unidad" headerStyle="width: 10%">
					<template #body="slotProps">
						{{slotProps.data.unidad_n}}
					</template>
				</Column>
                <Column field="disponibilidad" header="Cantidad" headerStyle="width: 10%">
					<template #body="slotProps">
						{{slotProps.data.cantidad}}
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Inventario','Reporte');
	import BarcodeGenerator from "../components/BarcodeGenerator.vue";
    import PDF from "../service/PDF";
    import XLS from "../service/XLS";

	export default {
		components: {
			BarcodeGenerator
		},
		data() {
			return {
				actualizar: 0,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				filters: {},
				selectedCustomers: null,
				dialog_edit: false,
				dialog_del: false,
				size: '40vw',
				size2: '40vw',
				productos: [],
                almacenes: [],
                fecha_ini: this.$store.state.fechaini,
                fecha_fin: this.$store.state.fechaini,
                opcion: {id: 1, nombre: 'Inventario'},
                opciones: [
                    {id: 0, nombre: 'Inventario'},
                    {id: 1, nombre: 'Ingreso'},
                    {id: 2, nombre: 'Egreso'},
                ],
                codigo: null,
                nombre: null,
                almacen: null,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){
				this.size = '90vw';
				this.size2 = '90vw';
			}
            this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.almacenes = response.result.almacenes;
					this.Actualizar();
				}); 
			},
			Buscar() {
                if(this.almacen && this.fecha_ini && this.fecha_fin && this.opcion){
                    let product = {
                    almacen: this.almacen,
                    fecha_ini: this.fecha_ini,
                    fecha_fin: this.fecha_fin,
                    opcion: this.opcion,
                    }
                    Consulta.Procesar('Buscar',{
                        product: product,
                    }).then(response => {
                        //this.$store.state.error =response;
                        this.products = response.result;
                    }); 
                }
			},
            VerXLS(){
                const xls = new XLS();
                xls.Reporte(this.products,this.fecha_ini,this.fecha_fin);
            },
            VerPDF(){
                const doc = new PDF();
                doc.Reporte(this.products,this.fecha_ini,this.fecha_fin);
            },

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
